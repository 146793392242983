import React, { useState, useEffect } from 'react';

const RandomAnswer = props => {
  const [ alreadySelected, setAlreadySelected ] = useState([]);

  useEffect(() => {
    setAlreadySelected([]);
  }, [props.randomSolution]);

  const styles = {
    randomAnswer: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 30
    },
    word: {
      padding: 10,
      borderBottom: '2px solid red',
      margin: 5,
      fontSize: '1.5em',
      color: '#999999',
      cursor: 'pointer'
    },
    selected: {
      padding: 10,
      borderBottom: '2px solid grey',
      margin: 5,
      fontSize: '1.5em',
      color: 'grey'
    },
    number: {
      textAlign: 'center',
      color: 'yellow',
      width: '100%',
      fontSize: '0.9em'
    },
    none: {
      color: 'transparent'
    }
  }

  const checkAnswer = i => {
    if (!~alreadySelected.indexOf(i)) {
      let selected = [...alreadySelected];
      selected.push(i);
      setAlreadySelected(selected);
    }
    if (i === parseInt(props.answerIndex)) {
      props.handleAnswerCorrectly();
    }
  }

  return (
    <div style={styles.randomAnswer}>
      {
        props.randomSolution.map((word, i) => {
          return (
            <div key={i}>
              <div
                onClick={checkAnswer.bind(this, i)}
                style={~alreadySelected.indexOf(i) ? styles.selected : styles.word }>{ word }
              </div>
              <div style={styles.number}>
                {i+1}
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default RandomAnswer;