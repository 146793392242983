import React from 'react';
import Sound from 'react-sound';

import { sounds } from './util/data';
import { getRandomNum } from './util/util';

const PlaySound = props => {
  const getSound = _ => {
    let rand = getRandomNum(0,1);
    return sounds[rand];
  }

  return (
    <Sound 
      url={getSound()}
      playStatus='PLAYING'
      volume={70}
    />
  )
}

export default PlaySound;