import React from 'react';
import { Link }  from 'react-router-dom';

const Home = props => {
  const styles = {
    home: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      marginTop: 20,
      fontSize: '2.0em'
    },
    title: {
      fontSize: '1.5em',
      paddingBottom: 30
    },
    text: {
      padding: 10
    },
    link: {
      textDecoration: 'none'
    }
  };

  return (
    <div style={styles.home}>
      <div style={styles.title}>
        千字文
      </div>
      <div style={styles.text}>
        <Link style={styles.link} to='/all'>全文</Link>
      </div>
      <div style={styles.text}>
        <Link style={styles.link} to='/home'>測驗遊戲 (Game)</Link>
      </div>
    </div>
  )
}

export default Home;