import React from 'react';

const ActionButton = props => {
  const styles = {
    button: {
      border: '1px solid #ecd63a',
      borderRadius: 8,
      backgroundColor: '#c07c16',
      padding: 10
      
    }
  };

  return (
    <div onClick={props.handleOnClick.bind(this)} style={{...styles.button, ...props.style}}>
      { props.text }
    </div>
  );
}

export default ActionButton;