import React, { useState, useEffect } from 'react';
import Zoom from 'react-reveal/Zoom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import IndexSelectionPage from './IndexSelectionPage';
import QuestionLine from './QuestionLine';
import RandomAnswer from './RandomAnswer';
import ActionButton from './ActionButton';
import BackButton from './BackButton';
import PlaySound from './PlaySound';
import Completed from './Completed';

import { getRandomWords, getRandomLine, getRandomNum } from './util/util';
import { theThousandWords } from './util/data';

const ThousandWords = props => {
  // const [ allWords, setAllWords ] = useState(theThousandWords);
  const [ from, setFrom ] = useState(''); 
  const [ to, setTo ] = useState('');
  const [ testLine, setTestLine ] = useState('');
  const [ randomSolution, setRandomSolution ] = useState([]);
  const [ answer, setAnswer ] = useState([]);
  const [ answerWord, setAnswerWord ] = useState('');
  const [ answerIndex, setAnswerIndex ] = useState(null);
  const [ stepOne, setStepOne ] = useState(false);
  const [ maxLine, setMaxLine ] = useState(theThousandWords.length);
  const [ showAnswer, setShowAnswer ] = useState(false);
  const [ showCheckMark, setShowCheckMark ] = useState(false);
  const [ completed, setCompleted ] = useState(0);

  useEffect(() => {
    if (from !== '' && to !== '') {
      generateLine();
    }
  }, [stepOne]);

  useEffect(() => {
    // console.log(answerIndex, answer, maxLine);
    setMaxLine(theThousandWords.length);
    updateCompleted();
  }, [])

  const updateCompleted = _ => {
    let existingCompleted = window.localStorage.getItem('completed');
    if (existingCompleted) {
      existingCompleted = parseInt(existingCompleted);
    } else {
      existingCompleted = 0;
    }
    setCompleted(existingCompleted);
  }

  const generateLine = _ => {
    let fromIndex = from;
    let toIndex = to;

    if (from < 1) {
      fromIndex = 1;
    }

    if (to > maxLine) {
      toIndex = maxLine;
    }

    let theLineToTest = getRandomLine(fromIndex-1, toIndex-1);
    theLineToTest = theLineToTest.split('');
    const theAnswer = [...theLineToTest];
    const missingIndex = getRandomNum(0,theLineToTest.length-1);
    const missingCharacter = theAnswer[missingIndex];
    const { finalWords, solutionIndex } = getRandomWords(4, missingCharacter);

    theLineToTest.splice(missingIndex, 1, '*');
    
    setAnswerIndex(solutionIndex);
    setAnswer(theAnswer);
    setTestLine(theLineToTest);
    setRandomSolution(finalWords);
    setFrom(fromIndex);
    setTo(toIndex);
    setAnswerWord(missingCharacter);
  }

  const styles = {
    thousandWords: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: showAnswer ? '#0a145d' : '#000000'
    },
    actionButton: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 20
    },
    againButton: {
      backgroundColor: '#ece998',
      margin: 20,
      width: '30%'
    },
    resetButton: {
      backgroundColor: '#ece998',
      margin: 20,
      width: '30%'
    },
    control: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
      marginBottom: 10
    },
    controlSelect: {
      padding: 10
    },
    checkMark: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#00ff00'
    },
    icon: {
      fontSize: '5.0em'
    }
  };

  const handleControlChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name === 'from') {
      setFrom(value);
    } else {
      setTo(value);
    }
  }

  const handleIndexChange = _ => {
    if (from !== '' && to !== '') {
      setStepOne(true);
    }
  }

  const handleAgainButton = _ => {
    setStepOne(false);
    setTimeout(() => {
      handleIndexChange();
    }, 0);
    setShowAnswer(false);
    setShowCheckMark(false);
  }

  const handleResetButton = _ => {
    setTestLine('');
    setTo('');
    setFrom('');
    setStepOne(false);
    setShowAnswer(false);
    setShowCheckMark(false);
  }

  const isNoFromAndTo = _ => {
    return testLine === '';
  }

  const handleCompleted = _ => {
    let newCompleted = window.localStorage.getItem('completed');
    if (newCompleted) {
      newCompleted++;
    } else {
      newCompleted = 1;
    }
    setCompleted(newCompleted);
    window.localStorage.setItem('completed', newCompleted);
  }

  const handleAnswerCorrectly = _ => {
    handleCompleted();
    setShowAnswer(true);
    setShowCheckMark(true);
    setTimeout(_ => {
      setShowCheckMark(false);
    }, 3000);
  }

  const handleResetScore = _ => {
    setCompleted(0);
    window.localStorage.setItem('completed', 0);
  }

  if (isNoFromAndTo()) {
    return (
      <IndexSelectionPage
        from={from}
        to={to}
        handleControlChange={handleControlChange}
        handleIndexChange={handleIndexChange}
        maxLine={maxLine}
      />
    )
  }

  return (
    <div>
      { showCheckMark && (
        <div style={styles.checkMark}>
          <FontAwesomeIcon 
            icon={faCheck}
            style={styles.icon}
          />
          <PlaySound />
        </div>
      )}
      <div style={styles.thousandWords}>
        <Completed
          completed={completed}
          handleResetScore={handleResetScore}
        />

        <QuestionLine 
          testLine={testLine}
          showAnswer={showAnswer}
          answerWord={answerWord}
        />

        <RandomAnswer 
          randomSolution={randomSolution}
          answerIndex={answerIndex}
          handleAnswerCorrectly={handleAnswerCorrectly}
        />

        <div style={styles.actionButton}>
          <ActionButton
            handleOnClick={handleAgainButton}
            text="重來"
            style={styles.againButton}
          />

          <ActionButton
            handleOnClick={handleResetButton}
            text="重選"
            style={styles.resetButton}
          />
        </div>
        
        <BackButton />
      </div>
    </div>
  )
}

export default ThousandWords;