import React from 'react';
import { Link } from 'react-router-dom';

import ActionButton from './ActionButton';
import BackButton from './BackButton';

import { theThousandWords } from './util/data';

const IndexSelectionPage = props => {
  const styles = {
    indexSelectionPage: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#000000',
      fontSize: '1.5em',
      color: '#ffffff'
    },
    title: {
      fontSize: '1.5em',
      padding: 20
    },
    controlSelect: {
      padding: 10
    },
    input: {
      marginLeft: 10,
      fontSize: '20px'
    },
    submitButton: {
      marginTop: 10,
      textAlign: 'center'
    },
    sendButton: {
      backgroundColor: '#00ffff',
      margin: 30,
      color: '#000000'
    }
  };

  const handleIndexChange = event => {
    event.preventDefault();
    const fromInt = parseInt(props.from);
    const toInt = parseInt(props.to);
    if (isNaN(fromInt) || isNaN(toInt)) {
      return;
    }
    if (fromInt > toInt) {
      return;
    }
    if (!(fromInt < 1 || toInt > props.maxLine)) {
      props.handleIndexChange();
    }
  }

  const checkFrom = (props.from === '' || props.from < 1) ? { borderColor: 'red' } : {};

  const checkTo = (props.to === '' || props.to > props.maxLine) ? { borderColor: 'red' } : {};

  return (
    <div style={styles.indexSelectionPage}>
      <div style={styles.title}>千字文</div>
      <form onSubmit={handleIndexChange.bind(this)}>
        <div style={styles.controlSelect}>
          從 (最低: 1)
          <input
            type='number'
            style={{...styles.input, ...checkFrom}}
            name='from' 
            value={props.from}
            onChange={props.handleControlChange.bind(this)} 
          />
        </div>
        <div style={styles.controlSelect}>
          至 (最高: {theThousandWords.length})
          <input 
            type='number'
            style={{...styles.input, ...checkTo}}
            name='to' 
            value={props.to} 
            maxlength="5" 
            onChange={props.handleControlChange.bind(this)} 
          />
        </div>
        <div style={styles.submitButton}>
          <ActionButton
            handleOnClick={handleIndexChange}
            text="開始"
            style={styles.sendButton}
          />
        </div>

        <BackButton />
      </form>
    </div>
  )
}

export default IndexSelectionPage;