import React from 'react';

const QuestionLine = props => {
  const styles = {
    questionLine: {
      display: 'flex',
      flexDirection: 'row',
      color: '#ffffff'
    },
    word: {
      padding: 20,
      border: '2px solid #584fe7',
      borderRadius: 15,
      margin: 5,
      fontSize: '2.0em',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    final: {
      borderColor: '#33ec1d'
    },
    none: {
      color: 'transparent'
    },
    answer: {
      color: '#33ec1d',
      fontSize: '2.0em'
    }
  };

  // showAnswer={showAnswer}
  //         answer={answer}

  return (
    <div style={styles.questionLine}>
      {
        props.testLine.map((word, i) => {
          return (
          <div key={i} style={{...styles.word, ...props.showAnswer ? styles.final : {}}}>
            {
              word === '*' ? (
                <span style={props.showAnswer ? styles.answer : styles.none}>
                  { props.showAnswer ? props.answerWord : '無'}
                </span>
              ) : word
            }
          </div>
          )
        })
      }
    </div>
  )
}

export default QuestionLine;