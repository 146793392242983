import React, { useState, useEffect } from 'react';

import BackButton from './BackButton';

import { theThousandWords } from './util/data';

const ThousandWordsAll = props => {
  const [ allWords, setAllWords ] = useState([]);

  useEffect(() => {
    setAllWords(theThousandWords);
  }, [])

  const styles = {
    all: {
      textAlign: 'center',
      padding: 10,
      color: '#ffffff',
      backgroundColor: '#000000'
    },
    title: {
      fontSize: '2.0em',
      paddingTop: 30,
      paddingBottom: 20
    }
  };

  const getContent = _ => {
    let result = [];
    allWords.forEach((word, i) => {
      if (i !== 0) {
        result.push(<span>，</span>);
      }
      if (i !== 0 && i%3 === 0) {
        result.push(<br />);
      }
      result.push(<span>{word}</span>);
    });
    result.push('。');
    return <div>{result}</div>;
  }

  return (
    <div>
      <div style={styles.all}>
        <div style={styles.title}>千字文</div>
        {
          getContent()
        }

        <BackButton />
      </div>
    </div>
  )
}

export default ThousandWordsAll;