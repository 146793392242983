import React, { useState } from 'react';
import Zoom from 'react-reveal/Zoom';

const Completed = props => {
  const [ showOption, setShowOption ] = useState(false);

  const styles = {
    completed: {
      position: 'absolute',
      top: 20,
      right: 20,
      fontSize: '1.5em',
      color: '#00ff00',
      cursor: 'pointer'
    },
    option: {
      position: 'absolute',
      top: 30,
      right: 5,
      border: '1px solid #00ff00',
      borderRadius: 5,
      padding: 10,
      minWidth: 100,
      textAlign: 'right',
      cursor: 'pointer'
    }
  };

  const handleShowOption = _ => {
    let option = showOption;
    setShowOption(!option);
  }

  return (
    <div style={styles.completed} onClick={handleShowOption.bind(this)}>
      <Zoom spy={props.completed}>
        { props.completed }
      </Zoom>

      { showOption && (
        <div style={styles.option}>
          <div onClick={props.handleResetScore.bind(this)}>重設分數</div>
        </div>
      )}
    </div>
  )
}

export default Completed;