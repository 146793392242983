import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import ThousandWordsAll from './ThousandWordsAll';
import ThousandWords from './ThousandWords';
import ThousandWordsTest from './ThousandWordsTest';
import Home from './Home';

const AppRouter = props => {
  return (
    <Router>
      <div>
        <Route exact path="/" component={Home} />
        <Route exact path="/all" component={ThousandWordsAll} />
        <Route exact path="/test" component={ThousandWordsTest} />
        <Route exact path="/home" component={ThousandWords} />
        
      </div>
    </Router>
  )
}

export default AppRouter;