import React, { useState, useEffect } from 'react';

import { theThousandWords } from './util/data';
import { getRandomWords, getRandomLine } from './util/util';

const ThousandWordsTest = props => {
  const [ allWords, setAllWords ] = useState([]);

  useEffect(() => {
    setAllWords(theThousandWords);
  }, [])

  return (
    <div>
      <div>
        {
          allWords.map(word => word)
        }
      </div>

      {
        [1,1,1,1,1,1].map(_ => {
          return (
            <div style={{color: 'red'}}>
              {
                getRandomWords(4)
              }
            </div>
          )
        })
      }

{
        [1,1,1,1,1,1].map(_ => {
          return (
            <div style={{color: 'blue'}}>
              {
                getRandomLine()
              }
            </div>
          )
        })
      }
      
    </div>
  )
}

export default ThousandWordsTest;