import React from 'react';
import { Link } from 'react-router-dom';

const BackButton = props => {
  const styles = {
    backParent: {
      marginTop: 30,
      textAlign: 'center'
    },
    back: {
      textDecoration: 'none',
      color: '#999999'
    }
  }
  return (
    <div style={styles.backParent}>
      <Link to="/" style={styles.back}>首頁</Link>
    </div>
  )
}

export default BackButton;