import { theThousandWords } from './data';

const getRandomWords = (num, existingWords) => {
  let words = [];
  let finalWords = [];

  if (existingWords) {
    if (Array.isArray(existingWords)) {
      words = [...existingWords];
    } else {
      words.push(existingWords);
    }
  }

  while (words.length < num) {
    let newWord = getRandomWordFromOneLine();
    if (!(~words.indexOf(newWord))) {
      words.push(newWord);
    }
  }

  let index = 0;
  let solutionIndex;
  while (words.length > 0) {
    let random = getRandomNum(0, words.length-1);
    let thisWord = words[random];
    finalWords.push(thisWord);
    if (thisWord === existingWords) {
      solutionIndex = index;
    }
    index++;
    words.splice(random, 1);
  }

  return { finalWords, solutionIndex };
}

const getRandomWordFromOneLine = _ => {
  const randomLine = getRandomLine();
  const randomLineSplit = randomLine.split('');
  return randomLineSplit[getRandomNum(0, randomLineSplit.length-1)];
}

const getRandomLine = (from, to) => {
  let randomNum = 0;
  if (from !== undefined && to !== undefined) {
    randomNum = getRandomNum(from, to);
  } else {
    randomNum = getRandomNum(0,theThousandWords.length-1);
  }
  return theThousandWords[randomNum];
}

const getRandomNum = (min, max) => {
  let random = (Math.random()*(max-min))+min;
  return Math.round(random);
}

export {
  getRandomWords,
  getRandomNum,
  getRandomLine
};